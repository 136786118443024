<template>
  <v-container>
    <!-- <create-employee></create-employee> -->
    <tooltip-btn
      v-if="this.$route.name !== 'employees.create'"
      rounded
      tooltip="New Employee"
      @click="$router.push('/employees/create')"
    >
      <v-icon>mdi-account</v-icon>
      <v-icon>mdi-plus</v-icon>
    </tooltip-btn>
    <v-card class="mt-2">
      <router-view
        @open-dialog="openDialog"
      ></router-view>
    </v-card>
    <e-dialog
      v-model="dialog"
    ></e-dialog>
  </v-container>
</template>
<script>

import searchDelay from '@/libs/searchDelay.extra'

export default {
  name: 'EmployeeIndex',
  components: {
    // createEmployee: () => import('@/views/employees/Create'),
    eDialog: () => import('@/views/admin/employees/Dialog'),
    tooltipBtn: () => import('@/components/extra/TooltipBtn'),
  },
  data () {
    return {
      dialog: {
        dialog: false,
        employeeId: null,
        employeeName: null,
      },
    }
  },
  methods: {
    openDialog ({ employeeId, employeeName }) {
      this.dialog.dialog = true
      this.dialog.employeeId = employeeId
      this.dialog.employeeName = employeeName
    },
  },
}
</script>
